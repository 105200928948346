@import '../css/shared';

.swiper {

  &.auto-width {
    .swiper-slide {
      width: auto;
    }
  }

  .swiper-pagination .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin: 0 10px;

    @include media-breakpoint-down(md) {
      width: 10px;
      height: 10px;
      margin: 0 5px;
    }
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      background-color: var(--bs-white);
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--bs-yellow);
    }
  }

  .slide-image {
    a,
    picture {
      display: block;
    }

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  &.slide-content-centered {
    .swiper-slide {
      display: flex;
      align-items: center;
    }
  }

}

