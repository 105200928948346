@import 'css/shared';

.footer {
  position: relative;
  
  .accordion .row > [class^=col-] .accordion-item {
    border-bottom: 0 !important;
  }

  .footer-newsletter {
    input[type=email] {
      background-color: $pink;
      border-width: 0 0 1px 0;
      border-color: $gray-400;
      border-radius: 0;
    }
  }

  @include media-breakpoint-down(lg) {

    .footer-social {border-top: 1px solid $black;}

    .accordion {
      .accordion-item {
        border-style: solid;
        border-width: 1px 0 0 0;
        border-color: $black;
        border-radius: 0;
      }

      .accordion-header button {
        box-shadow: none;

      }
    }

    #first-footer-accordion {
      .col-lg:first-child {
        .accordion-item {
          border-top-color: transparent;
        }
      }
      
    }
  }
}