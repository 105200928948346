@import '../css/shared';

.cart-page {
  .cart-packs {
    border: 1px solid $gray-200;
    border-radius: $border-radius;

    .cart-pack {
      & > .cart-sku:first-child > div {
        padding-top: 0 !important;
      }
      & > .cart-sku:last-child > div {
        padding-bottom: 0 !important;
      }

      .cart-sku {
        .SeparatedList {
          .btn {
            font-weight: 300;
            color: $gray-400;
          }
        }

        @include media-breakpoint-up(lg) {
          .cart-sku-action {
            padding: map-get($spacers, 3) 0 !important;

            .cart-sku-price {
              font-size: $h5-font-size;
            }

            & > div:first-child {
              & > .row > .col {
                width: 100%;
                display: flex;
                justify-content: flex-end;

                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }

    img.img-fluid {
      // @include media-breakpoint-up(lg) {
      //   max-width: 160px !important;
      // }
      // max-width: 120px !important;
      border: 1px solid $gray-200;
      border-radius: $border-radius;
    }
  }

  .cart-summary {
    h6 {
      font-weight: 700;
    }

    .cart-to-free-shipping {
      border-radius: $border-radius;
      font-weight: 400;
    }

    & > div:last-child {
      h5 {
        padding: 15px 0;
        font-size: $h4-font-size;
        font-weight: 700;
      }

      border: 1px solid $gray-200;
      border-radius: $border-radius;
    }
  }

  .cart-footer {
    border: 1px solid $gray-200;
    border-radius: $border-radius;

    h6 {
      font-size: $h5-font-size;
      font-weight: 700;
    }
  }

  .Accordion {
    .row > [class^='col-'] {
      .accordion-item {
        border-bottom: 0;
      }
      &:last-child {
        .accordion-item {
          border-bottom: 1px solid var(--bs-light);
        }
      }
    }

    .accordion-item {
      border-left: 0;
      border-right: 0;
    }

    .accordion-button:not(.collapsed) {
      box-shadow: none !important;
    }

    @include media-breakpoint-down(lg) {
    }
    @include media-breakpoint-up(lg) {
    }
  }

  .cart-to-free-shipping {
    background: $secondary;
    font-weight: 700;
  }

  .generic-coupon-form {
    input {
      border-color: $gray-200;
      &:focus {
        border-color: $primary;
      }
      &.is-invalid {
        border-color: $red;
      }
    }

    input + .btn {
      border-radius: 0 $border-radius $border-radius 0 !important;
      border-left: 1px solid $gray-200 !important;
    }

    input:focus + .btn {
      border-color: $primary !important;
      border-left: 1px solid $primary !important;
    }
    input.is-invalid + .btn {
      border-color: $red !important;
      border-left: 1px solid $red !important;
    }
  }

  #form-cartGenericCoupon {
    input {
      border-color: var(--bs-dark) !important;
    }

    button {
      z-index: 100;
      opacity: 1 !important;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 14px);
        background: var(--bs-dark);
      }
    }
  }
}
