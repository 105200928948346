@import 'functions';
@import 'bootstrap';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

body {
  overflow-y: scroll;
}

.pagination {
  .arrow-prev,
  .arrow-next {
    .page-link {
      color: $primary;
      &:hover {
        border-color: $primary;
      }
    }
  }
}

.img-placeholder {
  width: 100%;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -45%;
    height: 100%;
    width: 45%;
    background-image: linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    background-image: -moz-linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    background-image: -webkit-linear-gradient(
      to left,
      rgba(251, 251, 251, 0.05),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.6),
      rgba(251, 251, 251, 0.3),
      rgba(251, 251, 251, 0.05)
    );
    animation: loading 1s infinite;
    z-index: 45;
  }

  @keyframes loading {
    0% {
      left: -45%;
    }
    100% {
      left: 100%;
    }
  }
}

.btn-outline-secondary {
  @include button-outline-variant($dark, $dark, $pink, $pink, $dark);
  background: $white;
  border-color: $pink;
  &.disabled,
  &:disabled {
    background: $white;
  }
}
