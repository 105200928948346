@import 'css/shared';

.product-page {

  .price-block {

    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-down(sm) {}

  }
 
}

.product-sticky-footer {

  .price-block {

    .crossed-out-price {
      color: $gray-400;
    }

    .discount-percentage {
      padding: 0 map-get($spacers, 2);
      background: $promo;
      color: $white;
      display: flex;
      align-items: center;
      border-radius: $border-radius-sm;
      text-transform: uppercase;
      font-size: $small-font-size;
    }

    .price {
      font-weight: 700;
      color: $black;
    }

    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-down(sm) {
      .discount-percentage {display: none !important;}
    }

  }
 
}

.product-tile {

  .price-block {

    @include media-breakpoint-up(sm) {}
    @include media-breakpoint-down(sm) {}

  }
  
}
