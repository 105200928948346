@import 'css/shared';

.social-sharing {
  .social-sharing-container {
    display: inline-block;
    position: relative;

    span.rounded-circle {
      width: 26px;
    }

    .social-sharing-group {
      align-items: center;
      flex-wrap: wrap;
      position: absolute;
      right: calc(100% + 15px);
      width: 100px;
      top: 0;
      padding: 5px;

      &::after {
        content: '';
        position: absolute;
        right: -9px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 8px 0px 8px 9px;
        border-color: transparent transparent transparent $pink;
      }
    }
  }
}
