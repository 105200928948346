@import 'css/shared';

.ProductTile .best-price {

    button {
        font-size: 0.75rem;
        line-height: 0.75rem;
        vertical-align: middle;
    }
    
}

.best-price-modal {

    .price-container {

        .price {
            font-size: $h5-font-size;
            font-weight: 600;
            margin-right: 1rem;
            color: $black !important;
            order: 1;
        }

        .crossed-out-price {
            font-size: $h5-font-size;
            order: 2;
        }

    }

    del {color: $gray-400;}

}