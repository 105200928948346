@import '../css/shared';

.product-page {
  overflow: hidden;
  max-width: 100%;

  .btn-wishlist-container {
    z-index: 100;
  }

  .product-icon {
    width: 6rem;
  }

  .thumbnail-container {
    .swiper {
      .swiper-slide {
        border-color: $gray-200 !important;

        &:hover {
          cursor: pointer;

          img {
            opacity: 0.7;
          }
        }

        &.swiper-slide-thumb-active {
          border-color: $plum !important;
        }
      }
    }
  }

  .reviews-summary-preview {
    .btn-review-first {
      background: $chilean-pink;
      text-decoration: underline;
      color: $black;
      font-weight: 400;
      border-color: $chilean-pink;
      font-size: $font-size-base * 0.875;
      padding: map-get($spacers, 1) map-get($spacers, 3);
    }

    .reviews-count {
      font-weight: 400;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }

  @include media-breakpoint-up(lg) {
  }

  @include media-breakpoint-down(lg) {
    .thumbnail-container {
      .swiper {
        overflow: visible;
      }
    }
  }
}
