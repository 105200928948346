@import '../css/shared';

.article-page {
  h1 {
    line-height: 1.11111;
  }

  .categories {
    overflow-x: auto;
    a {
      white-space: nowrap;
    }
  }
}
