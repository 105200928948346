@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Regular'), url('../fonts/Poppins/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), url('../fonts/Poppins/Poppins-SemiBold.woff') format('woff');
}

.small {
  font-size: $font-size-base * 0.875;
}

.extra-small {
  font-size: $font-size-base * 0.75;
}

.fw-semi-bold {
  font-weight: 600;
}

h1, .h1, .f1 {
  @include media-breakpoint-down(lg) {
    font-size: 28px;
  }
}

h2, .h2, .f2 {
  @include media-breakpoint-down(lg) {
    font-size: 24px;
  }
}

h3, .h3, .f3 {
  @include media-breakpoint-down(lg) {
    font-size: 20px;
  }
}

h4, .h4, .f4 {
  @include media-breakpoint-down(lg) {
    font-size: 18px;
  }
}

h5, .h5, .f5 {
  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}