@import '../css/shared';

.popup-newsletter-modal {
  .modal-content {
    .modal-header {
      padding-bottom: 0px;

      .Icon.icon-delete {
        fill: $white;
      }
    }
  }
}