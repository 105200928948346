@import '../css/shared';

.message-box-page {

  .dt, dd {
    word-wrap: break-word;
  }

  @include media-breakpoint-up(md) {

    .message-box-content {min-height: 800px;}

  }
  @include media-breakpoint-down(md) {}

}