@import '../css/shared';

.service-page {

  .aside-menu-title {
    text-transform: uppercase;
    font-weight: 600;
    background-color: $pink !important;
    margin-bottom: 0 !important;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  .aside-menu-item {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  .faq-title {
    text-transform: uppercase;
    font-weight: 600;
  }

}