@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>

$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$font-family-sans-serif: 'Poppins', sans-serif;
$font-size-base: 1rem; // 16px`

$font-weight-bold: 600;
$headings-font-weight: 400;

$h1-font-size: $font-size-base * 3.9375;
$h2-font-size: $font-size-base * 2.625;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.3125;

$input-btn-font-size-lg: 1rem;

$black: #161615;
$white: #fff;
$mint: #c7eac9;
$plum: #8e2243;
$pink-light: #fff8f8;
$pink: #ffe4e8;
$pink-dark: #f82665;
$chilean-pink: #dfc2c3;
$dark-sienna: #664849;
$gray-200: #e3e3e3;
$gray-400: #7d7d7d;
$promo: $plum;

$custom-colors: (
  'black': $black,
  'white': $white,
  'mint': $mint,
  'plum': $plum,
  'promo': $promo,
  'chilean-pink': $chilean-pink,
  'dark-sienna': $dark-sienna,
  'pink': $pink,
  'pink-light': $pink-light,
  'pink-dark': $pink-dark,
  'gray-200': $gray-200,
  'gray-400': $gray-400,
);

$light: $gray-200;
$body-bg: $white;
$body-color: $black;

$primary: $black;
$secondary: $pink;
$border-color: $gray-200;
$table-group-separator-color: $border-color;
$table-bg-scale: -10%;
$table-variants: (
  'light': shift-color($secondary, $table-bg-scale),
);

$btn-font-weight: 600;
$btn-font-size: $font-size-base * 0.875;

$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: $body-bg;
$accordion-button-active-color: $body-color;

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

$border-radius: 7px;
$border-radius-sm: 4px;

$pagination-color: $gray-200;
$pagination-focus-bg: $white;
$pagination-focus-color: $primary;
$pagination-hover-bg: $white;
$pagination-active-color: $primary;
$pagination-active-bg: $white;
$pagination-active-border-color: $primary;

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

// BOOTSTRAP OVERRIDES BEFORE IMPORT <<<<<

@import '../../node_modules/bootstrap/scss/variables';
//@import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');
