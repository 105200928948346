@import 'css/shared';

.Accordion {
  .row > [class^='col-'] {
    .accordion-item {
      border-bottom: 0;
      border-radius: 0 !important;
    }
    &:last-child {
      .accordion-item {
        border-bottom: 1px solid $gray-200;
      }
    }  
  }

  .accordion-item {
    border-left: 0;
    border-right: 0;
  }
  
  .accordion-button {
    border-radius: 0 !important;

    &:not(.collapsed){
      box-shadow: none !important;
    }

  }

  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-down(lg) {}

}
