@import '../css/shared';

.account-home-page {

  .box-home-account, .help {
    border-radius: $border-radius;
  }  
  .box-home-account-orders {
    .box-home-account {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    > div {
        border-bottom-left-radius:  $border-radius;
        border-bottom-right-radius:  $border-radius;
    }
  }
}