@import 'css/shared';

.Breadcrumb {
  .breadcrumb-item {
    &:before {
      font-size: 10px;
      margin: 0;
      margin-top: 3px;
    }

    &.active {
      font-weight: 600;
      a {
        font-weight: 400;
      }
    }
  }
}
