@import '../css/shared';

.landing-page-generic {

  h1, h2, h3, h4, h5 {
    font-weight: 600;
  }

  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-down(lg) {}

}