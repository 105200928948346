@import '../css/shared';

.slider-a {

  position: relative;

  .outer-container {
    position: absolute;
    max-width: $navigationContainer;
    width: 100%;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .swiper-slide {
    position: relative;
  }

  .Slider-button.swiper-button-prev.swiper-button-large, 
  .Slider-button.swiper-button-next.swiper-button-large {
    background: transparent !important;
    border: 0 !important;
    transform: translate(0, -50%);

    svg {
      fill: #FFFFFF;
    }
  }

  @include media-breakpoint-up(lg) {

    .slide-text-container {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 100;
      left: 0;
      top: 0;
      pointer-events: none;

      .slide-text-content {
        width: 100%;
        max-width: 450px;
        pointer-events: auto;
      }

    }

  }

  @include media-breakpoint-down(lg) {

    .slide-text-content {
      color: #000000 !important;
      text-align: center !important;

      .btn {
        background: #000000 !important;
        color: #FFFFFF !important
      }
    }
    
  }

}