@import '../css/shared';

.slider-articles {
  svg {
    fill: #000000;
  }
  .swiper-rtl {
    .content-wrapper {
      direction: ltr !important;
    }
    .swiper-button-prev {
      right: 5px;
    }
    .swiper-button-next {
      left: 5px;
    }
  }

  &.swiper-button-prev {
    left: 5px;
    padding-right: 3px;
  }
  &.swiper-button-next {
    right: 5px;
    padding-left: 3px;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 35px;
    height: 35px;
    background: #ffffff !important;
    margin: auto;
    transition: opacity 0.3s;
    border: 1px solid $gray-200;
    border-radius: 50%;
    top: 0;
    bottom: 0;

    &:after {
      font-size: 16px;
      color: #000;
    }

    &.swiper-button-disabled {
      opacity: 0;
      transition: opacity 0s;
    }
  }
  @include media-breakpoint-down(lg) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }
}
