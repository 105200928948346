@import 'css/shared';

.product-tile {

  z-index: 100;

  .wishlist-action-container {
    z-index: 1000;
  }

  &.hover {
    box-shadow: 0 0 7px 0 rgba(125,125,125,0.7);
  }

  .swiper {
    margin-left: 0;
  }

  .Slider-button.swiper-button-prev.swiper-button-small, 
  .Slider-button.swiper-button-next.swiper-button-small {
    border: 0;
    border-radius: 0;
    position: relative;
    width: 35px;
  }

  .Slider-button.swiper-button-prev.swiper-button-small {left: 0;}
  .Slider-button.swiper-button-next.swiper-button-small {right: 0;}

  .Slider-button.swiper-button-prev.swiper-button-disabled, 
  .Slider-button.swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    transition: opacity 0s;
  }

  // .product-tile-basic-info {}
  // .product-tile-extra-info {}

  .best-price {
    svg {
      font-size: 14px !important;
      margin-top: -1px;
    }
  }

  .reviews-summary-preview {

    font-size: $font-size-base * 0.875;

    svg {
      width: 12px;
      height: 12px;
    }

  }

  /*// -- Inizio implementazione immagine secondaria -- //*/

  .alternative-image {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
  }

  @include media-breakpoint-up(lg) {

    .product-tile-media-container {
      overflow: hidden;

      .alternative-image {
        display: block;
        opacity: 0;
        transition: opacity 0.4s;
      }
    
    }
    
    & :hover {
    
      .alternative-image {
        opacity: 1;
      }  
    
    }
    
  }

  /*// -- Fine implementazione immagine secondaria -- //*/
    
  @include media-breakpoint-up(lg) {

    .product-tile-media-container {
      font-size: $font-size-base * 0.875;
    }

    .sticker-new-container {text-align: center;}

    .submit-button,
    .quantity-select {width: 70px;} 

  }

  @include media-breakpoint-down(lg) {

    .product-tile-media-container {
      font-size: $font-size-base * 0.75;
    }

  }
  .sticker-container, .sticker-promo-container {
    font-weight: 700;
  }
  .sticker-promo-container {
    z-index: 3;
  }
  
}
