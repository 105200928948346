@import '../shared';

.cart-sku {
  .product-name {
    font-weight: 700;
    margin-bottom: map-get($spacers, 2) !important;
  }

  .product-sku-name {
    font-size: $font-size-base * 0.875;
  }
}
