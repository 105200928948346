@import '../css/shared';

$zindex-starting-point: 1000;
$distance-badge-bg-color: $primary;
$distance-badge-text-color: $white;
$on-map-item-padding: $btn-padding-y;

@import '../../node_modules/@b2x/react/src/storeLocator/store-locator.scss';

.gm-style img {
    max-width: 35px;
}
