@import '../css/shared';

.newsletter-subscribe-page {

  .NewsletterForm {

    .cta-container  {text-align: center !important;}
    
  }
  
  @include media-breakpoint-up(lg) {

    .page-content {padding: 50px 0 300px;}

  }

  @include media-breakpoint-down(lg) {}

}