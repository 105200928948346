@import '../css/shared';

.account-page {
  .inner-box {
    border-radius: $border-radius !important;
    height: 100%
  }
  .menu-account {
    .list-group {

        .list-group-item {
          border-radius: $border-radius !important;
          margin-bottom: spacer(2) !important;
          &.active {
                background-color: $pink !important;
            }
            .Icon {
              font-size: 30px !important;
            }
          }
    }
  }
}