@import 'css/shared';

.page-header {

  position: relative;
  
  @include media-breakpoint-up(lg) {

    .page-header-copy-container {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 100;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      .page-header-copy {
        max-width: 500px;
        width: 100%;
      }

    }

  }

  @include media-breakpoint-down(lg) {

    .page-header-copy {
      color: $dark !important;
      text-align: center;
    }

  }

}
