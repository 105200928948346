@import '../css/shared';

.blog-page {
  .first-level-category,
  .second-level-category {
    overflow-x: auto;
    a {
      white-space: nowrap;
      &:focus {
        box-shadow: none;
      }
    }
    span {
      white-space: nowrap;
    }
  }
}
.content-wrapper {
  .content-bottom-wrapper {
    overflow-x: auto;
    a {
      white-space: nowrap;
    }
    .tags {
      a {
        text-decoration: none;
      }
    }
  }
}
