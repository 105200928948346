@import '../css/shared';

.slider-b {

  max-width: 100%;
  overflow: hidden;

  .outer-container {
    position: relative;
    max-width: $navigationContainer;
    width: 100%;
    margin: 0 auto;

    @include media-breakpoint-down(xl) {
      & > .Slider-button {display: none !important;}
    }
    
  }

  .swiper {

    .swiper-slide {
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background: #FFFFFF;
        transition: all 0.5s;
      }

      &:not(.swiper-slide-visible):before {
        opacity: 0.5;
        background: #FFFFFF;
        z-index: 1000;
      }

    }

  }

  @include media-breakpoint-up(xl) {}
  @include media-breakpoint-down(xl) {}
  
}