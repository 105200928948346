@import 'css/shared';

.btn-check:disabled + .custom {
  .CustomRadio {
    opacity: 0.25;
    border: 2px solid $gray-300;
    cursor: not-allowed;
    &:before {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      border-top: 1px solid $gray-300;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
      transform-origin: center;
    }
  }
}

.btn-check + .custom {
  .CustomRadio {
    border: 1px solid $gray-300;
  }
}

.btn-check:checked + .custom {
  .CustomRadio {
    border: 2px solid $primary;
    background: $gray-100
  }
}

.btn-check.is-invalid + .custom {
  .CustomRadio {
    border: 2px solid $red;
  }
}

.CustomRadio {
  height: 35px;
  position: relative;
  cursor: pointer;

  &.rounded-circle {
    width: 35px;
    overflow: hidden;
  }
}
