@import '../css/shared';

.search-page {
  
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-down(lg) {

    .search-page-toolbar {
      background: $pink-light;
    
      .container-xxl {
        padding: 0;

        .SearchForm {
          padding: 0 0.75rem;
          overflow-x: scroll;
          white-space: nowrap;
        }

      }

    }

  }

}