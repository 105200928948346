@import '../css/shared';

.box-content {
  border-radius: $border-radius !important;
}

.form-group .form-label.required:after {
  color: $promo;
  content: '*';
  margin-left: 0.25rem;
}
