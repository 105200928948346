@import '../css/shared';

.Slider-button {

  svg {
    fill: #000000;
  }

  &.swiper-button-prev {left: 5px; padding-right: 3px;}
  &.swiper-button-next {right: 5px; padding-left: 3px;}

  &.swiper-button-prev.swiper-button-large {padding-right: 5px;}
  &.swiper-button-next.swiper-button-large {padding-left: 5px;}

  &.swiper-button-prev,
  &.swiper-button-next {
    background: #FFFFFF !important;
    margin: auto;
    transition: opacity 0.3s;
    border: 1px solid $gray-200;
    border-radius: 50%;
    top: 0;
    bottom: 0;    

    &.swiper-button-large {
      width: 55px;
      height: 55px;
    }

    &.swiper-button-small {
      width: 35px;
      height: 35px;
    }

    &:after {
      display: none;
    }

    &.swiper-button-disabled {
      opacity: 0;
      transition: opacity 0s;
    }

  }

  @include media-breakpoint-down(xl) {
    
    &.swiper-button-prev,
    &.swiper-button-next {
      display: none;
    }

  }

}