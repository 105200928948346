@import '../css/shared';

.slider-c {

  max-width: 100%;
  overflow: hidden;

  .outer-container {
    position: relative;
    max-width: $navigationContainer;
    width: 100%;
    margin: 0 auto;
  }
  
  .swiper {
    overflow: visible;

    .swiper-slide {
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background: #FFFFFF;
        transition: all 0.5s;
      }

      &:not(.swiper-slide-visible):before {
        opacity: 0.5;
        z-index: 10;
        background: #FFFFFF;
      }

    }

  }

  @include media-breakpoint-up(xl) {}
  @include media-breakpoint-down(xl) {
    .Slider-button {display: none !important;}
  }
  
}